
import { mapActions, mapMutations, mapState } from "vuex";
import { Component, Prop, Vue } from "vue-property-decorator";
import Jitsi from "@/components/Jitsi.vue";
import { IResult } from "@/schemas/IResult";
import { IMessage } from "@/schemas/IMessage";
import { IUser } from "@/schemas/IUser";
import { randomColor } from "@/utils";
import WebSocketManager from "@/utils/websocketManager";
import ChatBubble from "@/components/task/common/chat/ChatBubble.vue";
import { webSocketUrl } from "@/env";
import { NavigationGuardNext, Route } from "vue-router";

@Component({
  name: "ChatRoom",
  components: { ChatBubble, Jitsi },
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("chatroom", ["messages"]),
    ...mapState("session", ["session", "users"]),
  },
  methods: {
    ...mapActions("chatroom", ["fetchMessages"]),
    ...mapMutations("chatroom", {
      addMessage: "ADD_MESSAGE",
    }),
    ...mapMutations({ setSnack: "SET_SNACK" }),
  },
})
export default class ChatRoom extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly sessionId!: string;

  @Prop({ type: Boolean, required: false, default: false })
  displayHighlightNotification: boolean | undefined;

  chatWS: WebSocketManager | null = null;

  private scrollInvoked = 1000;
  message: string | null = null;
  lastMessage: { content?: string; author_id?: string } = {};
  userId: string | undefined;
  messages: IMessage[] | undefined;

  heartbeatId = 0;
  lastHeartbeatId = 0
  interval = null;

  fetchMessages: any;
  addMessage: any;
  session: any;
  results: IResult[] | undefined;
  fullyLoaded = false;

  created(): void {
    // We need the results to know the user colors
    this.$store.dispatch("session/getSessionResults", {
      sessionId: this.sessionId,
      next: (results: IResult[]) => {
        this.results = results;
        this.fullyLoaded = true;
      },
    });
  }

  connectChatWS(): void {
    let chatWSUrl = `${webSocketUrl}/chat/${this.sessionId}/${this.userId}`;
    this.chatWS = new WebSocketManager(
      chatWSUrl,
      this.handleIncomingMessage,
      this.onRetryFail,
      undefined,
      this.onConnect
    );

  }

  onConnect(): void {
    console.log("Successfully connected to the chat.");

    this.interval = setInterval(() => {
      if (this.heartbeatId - this.lastHeartbeatId > 3) {
        console.log("Closing the chat WS due to inactivity...");
        //this.chatWS?.close(1000, "No heartbeat sent in the last 3 seconds");
        this.setSnack({
          snackText:
            "La connexion WebSocket du chat a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
          snackColor: "error",
          snackClosable: true,
        });
        this.lastHeartbeatId = this.heartbeatId;

        window.clearInterval(this.interval)
        setTimeout(() => {
          this.connectChatWS();
        }, 1000);
      }

      this.chatWS?.send(
        {
          type: "heartbeat",
          heartbeat_id: this.heartbeatId
        },
        () => {
          console.log("Error while sending heartbeat to the chat WS");
        }
      );
      this.heartbeatId++;
    }, 1000);
  }

  onRetryFail(event: CloseEvent): void {
    // Alert user and provide a user-friendly message
    this.setSnack({
      snackText:
        "La connexion WebSocket du chat a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
      snackColor: "error",
      snackClosable: true,
    });
  }

  get chatMessages(): IMessage[] {
    return this.messages || [];
  }

  get messageNumber(): number {
    return this.chatMessages.length;
  }

  // Get the scrollable ref
  get scrollable(): Vue & {
    childElementCount: number;
    scrollTo: CallableFunction;
    scrollHeight: number;
  } {
    return this.$refs.scrollable as Vue & {
      childElementCount: number;
      scrollTo: () => undefined;
      scrollHeight: number;
    };
  }

  updated(): void {
    if (this.messageNumber < this.scrollable?.childElementCount) {
      this.scrollable.scrollTo({
        top: this.scrollable.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  mounted(): void {
    if (this.sessionId) {
      this.fetchMessages(this.sessionId);
      this.connectChatWS();
    }
  }

  beforeDestroy(): void {
    this.closeWS();
    if (this.interval) {
      window.clearInterval(this.interval)
    }
  }

  deactivated(): void {
    this.closeWS();
  }
  // Handles when the scroll of scrollable HTMLElement change
  onScroll(): void {
    this.scrollInvoked++;
  }
  // Handles when a message is received on the websocket channel
  handleIncomingMessage(message: {
    message: { content: any; author_id: any };
  }): void {
    const msg: any = message;
    if (msg.type === "heartbeat") {
      this.lastHeartbeatId = msg.heartbeat_id;
      return;
    }



    if (this.lastMessage) {
      if (this.lastMessage.content === message.message.content) {
        if (this.lastMessage.author_id === message.message.author_id) {
          return;
        }
      }
    }
    this.addMessage(message.message);
    this.$nextTick(() => {
      this.scroll();
    });
    this.lastMessage = message.message;
  }

  clearMessage(): void {
    this.message = null;
  }

  // Send a message on the websocket channel
  sendMessage(): void {
    // Prevent from sending empty messages
    if (this.message && !this.message.match(/^\n+$|^\s+$/g)) {
      let message = {
        session_id: this.sessionId,
        author_id: this.userId,
        content: this.message,
        type: "text",
      };

      this.chatWS.send({ message, type: "message" }, () =>
        this.onSendMessageError(message)
      );
      this.clearMessage();
    }
  }

  /**
   * Callback triggered when there's an issue while sending message
   * @param message sent message
   */
  onSendMessageError(message: {
    session_id: string;
    author_id: string | undefined;
    content: string;
    type: string;
  }): void {
    this.setSnack({
      snackText: "Une erreur s'est produite lors de l'envoi du message",
      snackColor: "error",
      snackClosable: true,
    });
  }

  // Retrieves users names
  getUserName(userId: string): string {
    return this.users.find((user: IUser) => user.id === userId)?.username || "";
  }

  onKeyPressed(event: KeyboardEvent): void {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }

  getUserColor(userId: string): string {
    if (this.results) {
      let userResult = this.results.find(
        (res: IResult) => res.user_id == userId
      );
      return (
        userResult?.color ||
        randomColor(
          this.users.findIndex((user: IUser) => user.id === userId)
        ) ||
        ""
      );
    }
    return (
      randomColor(this.users.findIndex((user: IUser) => user.id === userId)) ||
      ""
    );
  }

  scroll(): void {
    this.scrollable.$el.scrollTop = this.scrollable.$el.scrollHeight;
  }

  closeWS(): void {
    this.chatWS.close(1000, "Websocket connection normally closed by the app");
  }

  // clearWS(): void {
  //   this.chatWS.close("3333", "click on chat btn");
  // }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.interval) {
      window.clearInterval(this.interval)
    }
    next();
  }
}
